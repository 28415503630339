import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'shared/Alert';
import * as Iconos from 'images';
import { useMedia } from 'hooks';

const NotWorking = () => {
    const { taskCounter, synchronizeTasks } = useMedia();
    const navigate = useNavigate();

    const handleChangeView = () => {
        if (navigator.onLine) {
            if (taskCounter > 0) {
                Alert("Parece que tienes novedades pendientes por sincronizar. \nPor favor, asegúrate de sincronizarlas para mantener tus registros actualizados. \n¡Gracias!", "info");
            } else {
                navigate("/qr-scanner");
            }
        } else {
            Alert("Necesitas de conexión a internet para registrar un ingreso", "wifi_off");
        }
    }

    return (
        <>
            <div className="card_state yellow">
                <h1>USTED AÚN NO HA REGISTRADO SU NOVEDAD DE INGRESO, RECUERDE QUE DEBE ACERCARSE AL CÓDIGO QR QUE SE ENCUENTRA UBICADO EN EL LUGAR INDICADO POR DISTRIBUCIÓN.</h1>
            </div>

            {taskCounter > 0 && <button type="button" className="btn btn_error" onClick={() => synchronizeTasks()} ><Iconos.ClockAsync /> Sincronizar novedades</button>}
            <button type="button" className="btn btn_primary" onClick={handleChangeView}><Iconos.ClockCheck /> Registrar ingreso</button>
        </>
    );
}

export default NotWorking;