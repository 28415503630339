import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'shared/Alert';
import Loading from 'shared/Loading';
import { useMedia } from 'hooks';
import api from 'services/api';

const ConfirmClose = () => {
    const { info, setInfo, setTask } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);
    const navigate = useNavigate();

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    /**
     * Maneja la acción del botón cancelar 
     */
    const handleCancel = () => {
        setInfo({
            working: 1 // Revierte el Estado y lo deja `activo`. 
        });
    }

    /**
     * Maneja la acción del botón aceptar. 
     */
    const handleAcept = () => {
        if (navigator.geolocation) {
            setShowLoading(true);

            /**
             * Este método del sistema obtiene la ubicación actual del dispositivo.  
             */
            navigator.geolocation.getCurrentPosition(
                /**
                 * Maneja la obtención de la posición del usuario mediante el objeto proporcionado por el navegador.
                 * 
                 * @param {Object} pos - Objeto que contiene información sobre la posición del usuario
                 */
                (pos) => {
                    let _current_date = getNowDate();
                    let _json = {
                        employee_id: info.id,
                        cost_center_uuid: info.cost_center_uuid,
                        cost_center_name: null,
                        cost_center_code: null,
                        type: 2, // 2 reporta salida
                        date: _current_date,
                        novelty: null,
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                        // lat: "10.425628",
                        // lng: "-75.537717",
                    };

                    if (navigator.onLine) {
                        handleTimeRecordingRemote(_json);
                    } else {
                        handleTimeRecordingLocal(_json);
                    }
                },
                /**
                 * Maneja errores que puedan surgir durante la obtención de la posición del usuario.
                 * 
                 * @param {Error} error - Objeto que representa el error ocurrido durante la obtención de la posición.
                 */
                (error) => {
                    console.error('Error al obtener la ubicación:', error);
                    Alert('Error al obtener la ubicación: ' + error.message, "warning");
                    setShowLoading(false);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 30000,
                    maximumAge: 0,
                }
            );
        } else {
            Alert("Geolocalización no es soportada por este navegador.", "warning");
        }
    }

    /**
     * Obtiene fecha actual con zona horaria de America/Bogota y formato YYYY-MM-DD HH:MM:SS
     * @returns String fecha actual
     */
    const getNowDate = () => {
        // Obtiene la fecha y hora actual en la zona horaria UTC
        const dateUTC = new Date();

        // Ajusta la fecha y hora a la zona horaria America/Bogota
        const dateBogota = new Date(dateUTC.toLocaleString('en-US', {
            timeZone: 'America/Bogota'
        }));

        // Formatea la fecha usando toLocaleString
        const formattedDate = dateBogota.toLocaleString('es-CO', {
            timeZone: 'America/Bogota',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });


        // Reorganiza el string para obtener el formato deseado
        const [date, time] = formattedDate.split(', ');
        const [day, month, year] = date.split('/');

        return `${year}-${month}-${day} ${time}`;
    }

    /**
     * Esta función notifica al centro de servicios, la salida del trabajador.
     * 
     * @param {JSON} param - JSON con la información enviar
     */
    const handleTimeRecordingRemote = async (param) => {
        let _data = await api.fetchAuth({
            url: "payroll-news/employee-time-recording",
            method: 'POST',
            token: info.token,
            data: param
        });

        if (_data.success && _data.type_message === "success") {
            let _created_at = new Date(_data.data.created_at);
            setInfo({ prework: 2 }); // 2 Estado inactivo

            Alert(`Gracias por realizar el reporte, su novedad de salida ya quedó contabilizada en nuestro sistema, el día ${format(_created_at.getDate())} a la hora ${format(_created_at.getHours())}:${format(_created_at.getMinutes())}`, "ok", () => {
                setInfo({ working: 2 }); // 2 Estado inactivo
            });
        } else if (_data.type_message === "unauthenticated_error") {
            Alert("Tu sesión ha vencido. Por favor inicia sesión nuevamente", "warning", () => {
                navigate("/login");
            });
        } else {
            Alert('Ocurrio un error: ' + _data.message, "warning");
        }

        setShowLoading(false);
    }

    const handleTimeRecordingLocal = async (param) => {
        let _created_at = new Date();

        Alert(`Gracias por realizar el reporte, su novedad de salida ya quedó contabilizada en nuestro sistema, el día ${format(_created_at.getDate())} a la hora ${format(_created_at.getHours())}:${format(_created_at.getMinutes())}`, "ok", () => {
            setInfo({ working: 2 }); // 2 Estado inactivo
        });

        setTask(param);
        setInfo({ prework: 2 }); // 2 Estado inactivo
        setShowLoading(false);
    }

    /**
     * Esta función pide permisos de ubicación 
     * Se llama en el primer renderizado del componente 
     */
    const requestPositionPermission = () => {

        /**
         * Verificar si el navegador es compatible con la geolocalización
         */
        if (!navigator.geolocation) {
            Alert('Error: la geolocalización no es compatible con su navegador', "warning");
            return;
        }

        /**
         * Este método del sistema pide los permisos de ubicación. 
         * En este caso no se usa para nada mas. 
         */
        navigator.geolocation.getCurrentPosition(
            () => {
                console.log("Paso datos de ubicación");
            },
            (error) => {
                console.error('Error al obtener la ubicación:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 0,
            });
    }

    React.useEffect(() => {
        requestPositionPermission();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card_state gray">
            <h1>Si aceptas, se reportará la salida registrando la hora y la localización del lugar donde te encuentras.</h1>
            <button type="button" className="btn btn_secondary" onClick={handleCancel}> Cancelar</button>
            <button type="button" className="btn btn_primary" onClick={handleAcept}> Aceptar</button>
            <Loading state={showLoading} />
        </div>
    );
}

export default ConfirmClose;