import * as React from 'react';
import logo from 'images/logo/logo-entity-blanco.png';
import { useMedia } from 'hooks';
import api from 'services/api';


const Splash = ({ onLoad }) => {
    const { info, setInfo } = useMedia();


    /**
     * Esta función obtiene un token nuevo desde el centro de servicios para mantener la sesión activa.
     */
    const renewToken = async () => {
        let _data = await api.fetchAuth({
            url: "auth/renew-token",
            method: 'GET',
            token: info.token,
            data: {}
        });

        if (_data.success) {
            validateStatus(_data.data.token);
        } else {
            setInfo({
                auth: false,
                error: _data.message
            });

            setTimeout(() => {
                onLoad(false);
            }, 2000);
        }
    }

    /**
     * Esta función obtiene el estado del trabajador desde el centro de servicios
     * y actualiza los datos del usuario en local storage. 
     * 
     * @param {string} _token - El nuevo token en un formato de texto. 
     */
    const validateStatus = async (_token) => {
        if (info.auth) {
            let _data = await api.fetchAuth({
                url: "payroll-news/employee-time-recording/employe-current-working-state/" + info.id,
                method: 'GET',
                token: _token,
                data: {}
            });

            if (_data.success) {
                setInfo({
                    working: _data.data.working || 2, // por defecto 2 inactivo
                    auth: true,
                    id: _data.data.id,
                    name: _data.data.name,
                    last_name: _data.data.last_name,
                    full_name: _data.data.full_name,
                    token: _token,
                    cost_center_uuid: _data.data.working_cost_center_uuid
                });
            } else {
                setInfo({
                    auth: false
                });
            }
        } else {
            setInfo({
                auth: false
            });
        }

        setTimeout(() => {
            onLoad(false);
        }, 2000);
    }

    React.useEffect(() => {
        document.title = "Organización Gen";
        setInfo({ check: true });

        if (navigator.onLine) {
            renewToken();
        } else {
            setTimeout(() => {
                onLoad(false);
            }, 3000);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <main className="splash">
                <div className="splash_img">
                    <img src={logo} alt="Logo organización gen" />
                </div>
            </main>
            <footer>
                <div id="modal"></div>
            </footer>
        </>
    );
}

export default Splash;