import * as React from "react";
import * as Iconos from "images";
import { useMedia } from "hooks";

const Header = () => {
    const { info, setInfo } = useMedia();

    /**
     * Maneja la acción del botón cancelar 
     */
    const handleLogOut = () => {
        let _url = window.location.href.toString().split("/").pop();

        if (_url === "qr-scanner") {
            setInfo({
                camera: info.camera === "user" ? "environment" : "user",
            });
        } else {
            setInfo({
                working: -1,
                old_working: info.working
            });
        }
    }

    return (<header>
        <div className="menu_logo"></div>
        <button type="button" className="_close" onClick={handleLogOut} >{info?.icono === "camera" ? <Iconos.CameraFlip /> : <Iconos.Logout />} </button>
    </header>);
}

export default Header;