import * as React from 'react';
import QrScanner from "qr-scanner";
import { Alert } from 'shared/Alert';
import { useMedia } from 'hooks';

/* eslint react-hooks/exhaustive-deps: 0 */

const Scanner = ({ onScan = () => { }, onError = () => { }, showScan }) => {
    const scannerRef = React.useRef();
    const videoRef = React.useRef(null);
    const { info, setInfo } = useMedia();
    const [qrActive, setQrActive] = React.useState(true);


    React.useEffect(() => {
        if (scannerRef.current) {
            scannerRef.current.setCamera(info.camera);
        }
    }, [info.camera]);

    React.useEffect(() => {
        let userAgent = navigator.userAgent.toLowerCase();
        let isIos = /iphone|ipad|ipod/i.test(userAgent); // android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);        

        if (videoRef?.current && !scannerRef.current) {
            // Crea una instancia del escáner QR
            scannerRef.current = new QrScanner(videoRef?.current, onScan, {
                onDecodeError: (error) => {
                    if (error === "Scanner error: No QR code found" || error === "No QR code found") {
                        // Dejo este condicional vacío para que no haga nada si es este tipo de error.
                    } else {
                        onError(error);
                    }
                },
                preferredCamera: isIos ? "user" : "environment", // Este es el modo de mirar hacia la cámara. En dispositivos móviles, 'environment', 'envirActivement', 'user', 'frActivet'.
                highlightScanRegion: true, // Esto nos ayudará a posicionar nuestro 'QrFrame.svg' de modo que el usuario solo pueda escanear cuando se coloque el código qr entre nuestro QrFrame.svg.
                highlightCodeOutline: true, // Esto producirá un contorno amarillo (color predeterminado) alrededor del código qr que escaneamos, mostrando una prueba de que nuestro escáner qr está escaneando ese código qr.
            });

            // Iniciar escáner QR
            scannerRef?.current
                ?.start()
                .then(() => setQrActive(true))
                .catch((err) => {
                    if (err) setQrActive(false);
                });
        }

        setInfo({
            camera: isIos ? "user" : "environment",
            icono: "camera"
        });

        // Limpiar al desmontar.
        // Esto evita que el escáner QR se renderice y use la cámara cuando está cerrado o eliminado de la interfaz de usuario.
        return () => {
            setInfo({
                icono: "login"
            });
            if (!videoRef?.current) {
                scannerRef?.current?.stop();
            }
        };
    }, []);

    React.useEffect(() => {
        if (!showScan) {
            scannerRef?.current?.stop();
        }
    }, [showScan]);

    React.useEffect(() => {
        if (!qrActive) {
            Alert("La cámara está bloqueada o no se puede acceder a ella. Permita los permisos de la cámara en su navegador y vuelva a cargar.", "warning");
        }
    }, [qrActive]);

    return (
        <div className="qr-reader">
            <video ref={videoRef}></video>
        </div>
    );
}

export default Scanner;