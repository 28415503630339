import * as React from 'react';
import ConfirmClose from './components/ConfirmClose';
import ConfirmLogOut from './components/ConfirmLogOut';
import NotWorking from './components/NotWorking';
import { useNavigate } from 'react-router-dom';
import Working from './components/Working';
import { useMedia } from "hooks";

const TimezoneClock = () => {
    const [currentTime, setCurrentTime] = React.useState(new Date());
    const [timezone, setTimezone] = React.useState('');

    React.useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        try {
            setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        } catch (error) {
            console.error('Error al obtener la zona horaria:', error);
            setTimezone('No se pudo determinar');
        }

        return () => clearInterval(timer);
    }, []);

    return `${timezone} | ${currentTime.toLocaleString()}`;
};

const Home = () => {
    const { info } = useMedia();
    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = "Registro de horas laborales";
        console.log("info", info);
        if (!info?.auth) {
            navigate("/login");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container">
            <h3 className="main_title">Hola {info?.full_name?.toLowerCase()}</h3>


            {info.working === -1 && <ConfirmLogOut />}
            {info.working === 0 && <ConfirmClose />}
            {info.working === 1 && <Working />}
            {[2, 3].includes(info.working) && <NotWorking />}
            <p className="p_version">Versión 1.0.{process.env.REACT_APP_BUILD_VERSION} | <TimezoneClock /> </p>

        </div>
    );
}

export default Home;