import * as React from 'react';
import { Alert } from 'shared/Alert';
import { useMedia } from 'hooks';
import * as Iconos from 'images';

const Working = () => {
    const { setInfo, taskCounter, synchronizeTasks } = useMedia();


    const handleReport = () => {
        if (taskCounter > 0) {
            if (navigator.onLine) {
                Alert("Parece que tienes novedades pendientes por sincronizar. \nPor favor, asegúrate de sincronizarlas para mantener tus registros actualizados. \n¡Gracias!", "info");
            } else {
                setInfo({
                    working: 0 // estado de confirmación
                });
            }
        } else {
            setInfo({
                working: 0 // estado de confirmación
            });
        }

    }

    return (
        <>
            <div className="card_state green">
                <h1>Usted tiene un registro de ingreso sin salida <br /> ¿Desea reportar la salida?</h1>
            </div>
            {taskCounter > 0 ?
                <button type="button" className="btn btn_error" onClick={() => synchronizeTasks()} ><Iconos.ClockAsync /> Sincronizar novedades</button>
                :
                <button type="button" className="btn btn_primary" onClick={handleReport}><Iconos.ClockRemove /> Reportar salida</button>
            }
        </>
    );
}

export default Working;