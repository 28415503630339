import * as React from 'react';
import { Route, Routes, Outlet } from "react-router-dom";

import Home from '../view/Home';
import LogIn from '../view/LogIn';
import Header from 'shared/Header';
import NoMatch from 'shared/NoMatch';
import ScannerQR from '../view/ScannerQR';


export default function Router() {

    const WrappedRoutes = () => {
        return (
            <>
                <Header />
                <main className="wrapper">
                    <Outlet />
                </main>
            </>
        );
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<WrappedRoutes />}   >
                    <Route path="/" element={<Home />} />
                    <Route path="/qr-scanner" element={<ScannerQR />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>

                <Route path="/login" element={<LogIn />} />
            </Routes>
            <footer>
                <div id="modal"></div>
            </footer>
        </>
    );
}
