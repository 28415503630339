import * as React from "react";
import * as ReactDOMClient from 'react-dom/client';
import * as Iconos from "images";

/* eslint jsx-a11y/anchor-is-valid: 0 */

/**
 * Componente que construye y muestra una alerta en el DOM.
 * Este componente no es público, se espera que sólo lo llame la función `Alert`. 
 * 
 * @param {ReactDOMClient.Root} modal - Elemento raíz donde se renderizará la alerta.
 * @param {String} msg - Mensaje a mostrar en la alerta.
 * @param {String} icon - Icono de la alerta `alert`, `warning`, `ok`, `info_circle`, `info`
 * @param {Function} action - Función a ejecutar cuando se interactúa con la alerta `callback`
 */
const AlertComponent = ({ modal, msg, icon, action }) => {
    let IconComponent;

    /**
     * Esta función desmonta el componente de alerta y ejecuta el callback `action`. 
     */
    const onDismiss = () => {
        action();
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    switch (icon) {
        case "alert":
        case "warning":
            IconComponent = <Iconos.AlertRhombus className="red"/>;
            break;
        case "ok":
            IconComponent = <Iconos.CheckBold className="green"/>;
            break;
        case "info_circle":
            IconComponent = <Iconos.InformationCircle />;
            break;
        case "wifi_off":
            IconComponent = <Iconos.WifiOff className="red" />;
            break;
        case "info":
        default:
            IconComponent = <Iconos.Information />;
            break;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        try {
            navigator.vibrate(250);
        } catch (e) {
            console.log('No se puede ejecutar el vibrador:', e);
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{IconComponent}</div>
            <div className="to_scroll">
                <p>{msg}</p>
            </div>
            <a className="close" onClick={onDismiss}>Aceptar</a>
        </div>
    );
}

/**
 * Muestra una alerta de tipo popup
 * 
 * @param {String} msg - Mensaje a mostrar en la alerta.
 * @param {String} icon - Icono de la alerta `alert`, `warning`, `ok`, `info_circle`, `info`
 * @param {Function} action - Función a ejecutar cuando se interactúa con la alerta `callback`
 */
export const Alert = (msg, icon = "info", action = () => {}) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <AlertComponent modal={_modal} msg={msg} icon={icon} action={action} />
        </div>);
}

/**
 * Muestra una alerta de tipo bocadillo para señalar un error en un formulario.
 * Se mantiene el nombre de la función en español para evitar conflictos con la función de alerta de JavaScript.
 * 
 * @param {HTMLElement} current_node - Nodo del DOM donde se mostrará la alerta.
 * @param {String} msg - Mensaje a mostrar en la alerta.
 */
export const alerta = (current_node, msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up";
        _new_node.innerText = msg;

        current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        console.log('No se puede ejecutar el vibrador:', e);
    }
}

/**
 * Función que elimina una alerta de tipo bocadillo de un nodo específico del DOM.
 * 
 * @param {HTMLElement} current_node - Nodo del DOM del cual se eliminará la alerta.
 */
export const alertRemove = (current_node) => {
    let _alerta = current_node == null ? null : current_node.nextSibling;

    if (_alerta != null) {
        current_node.classList.remove("alert__resalta_input");

        if (_alerta.className === "alert__input _up"
            || _alerta.className === "alert__input _down"
            || _alerta.className === "alert__input _up _area") {
            _alerta.parentNode.removeChild(_alerta);
        }
    }
}
