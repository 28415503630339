import * as React from "react";
import * as Iconos from "images";

/**
 * Componente reutilizable para dejar todos los input de la aplicación con este mismo formato. 
 * 
 * @param {Function} onChange - Función que se ejecuta cuando cambia el valor del input.
 * @param {String} name - Nombre asociado al input.
 * @param {String} title - Título o etiqueta descriptiva para el input.
 * @param {String} type - Tipo de input, por defecto es `text`. Puede ser `text`, `password`, `email`, etc.
 * @param {String} value - Valor inicial del input.
 * @param {React.Ref} ref - Referencia al input, útil para acceder al elemento de forma directa.
 */
export const Input = React.forwardRef(({
    onChange,
    name,
    title,
    type = "text",
    value
}, ref) => {
    return (
        <div className="form_group">
            <div className="form_group-field input_radius">
                <div className="input">
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        placeholder={title}
                        className="radius_all"
                        onChange={e => onChange(e.target.value)} />
                </div>
            </div>
        </div>
    )
});

/**
 * Componente reutilizable para un campo de contraseña.
 * Este componente proporciona un icono para alternar entre ver y ocultar la contraseña.
 * 
 * @param {Function} onChange - Función que se ejecuta cuando cambia el valor del campo de contraseña.
 * @param {string} autoComplete - Controla si el autocompletado está habilitado para el campo de contraseña. Por defecto es `on`.
 * @param {string} name - Nombre asociado al campo de contraseña.
 * @param {string} title - Título o etiqueta descriptiva para el campo de contraseña.
 * @param {string} value - Valor inicial del campo de contraseña.
 * @param {React.Ref} ref - Referencia al campo de contraseña, útil para acceder al elemento de forma directa.
 */
export const InputPassword = React.forwardRef(({
    onChange,
    autoComplete = "on",
    name,
    title,
    value
}, ref) => {
    const [isPasswordShown, setIsPasswordShown] = React.useState(false);

    return (
        <div className="form_group">
            <div className="form_group-field input_radius">
                <button type="button" className="form_group-button" onClick={() => setIsPasswordShown(current => !current)}>
                    {isPasswordShown ? <Iconos.EyeOff /> : <Iconos.Eye />}
                </button>
                <div className="input with_icon_right">
                    <input
                        ref={ref}
                        name={name}
                        autoComplete={autoComplete}
                        type={isPasswordShown ? 'text' : 'password'}
                        value={value}
                        placeholder={title}
                        className="radius_right"
                        onChange={e => onChange(e.target.value)} />
                </div>
            </div>
            <p className="p_message">Si no recuerdas tu código, por favor comunícate con tu gestor de servicio.</p>
        </div>
    )
});