import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Scanner from './components/ScannerQR';
import { Alert } from 'shared/Alert';
import Loading from 'shared/Loading';
import { useMedia } from 'hooks';
import api from 'services/api';


const ScannerQR = () => {
    const navigate = useNavigate();
    const { info, setInfo, setTask } = useMedia();
    const [showScan, setShowScan] = React.useState(true);
    const [showLoading, setShowLoading] = React.useState(false);


    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    /**
     * Esta función notifica al centro de servicios, el ingreso del trabajador.
     * 
     * @param {JSON} param - JSON con la información enviar
     */
    const handleTimeRecordingRemote = async (param) => {
        let _data = await api.fetchAuth({
            url: "payroll-news/employee-time-recording",
            method: 'POST',
            token: info.token,
            data: param
        });

        if (_data.success && _data.type_message === "success") {
            let _created_at = new Date(_data.data.created_at);

            Alert(`Gracias por realizar el reporte, su novedad de ingreso ya quedó contabilizada en nuestro sistema, el día ${format(_created_at.getDate())} a la hora ${format(_created_at.getHours())}:${format(_created_at.getMinutes())}`, "ok", () => {
                setInfo({
                    working: 1, // Estado activo
                    cost_center_uuid: param.cost_center_uuid,
                });

                navigate("/");
            });
        } else if (_data.type_message === "unauthenticated_error") {
            Alert("Tu sesión ha vencido. Por favor inicia sesión nuevamente", "warning", () => {
                navigate("/login");
            });
        } else {
            Alert('Ocurrio un error: ' + _data.message, "warning");
        }

        setShowLoading(false);
    }

    const handleTimeRecordingLocal = async (param) => {
        let _created_at = new Date();

        Alert(`Gracias por realizar el reporte, su novedad de ingreso ya quedó contabilizada en nuestro sistema, el día ${format(_created_at.getDate())} a la hora ${format(_created_at.getHours())}:${format(_created_at.getMinutes())}`, "ok", () => {
            setInfo({ working: 1 }); // Estado activo
            navigate("/");
        });

        setTask(param);

        setShowLoading(false);
    }

    /**
     * Esta función maneja la respuesta tras escanear el código QR. 
     * 
     * @param {string} data - El dato entregado por el código QR 
     */
    const handleScan = (data) => {
        data = data?.data;

        setShowScan(false);
        if (navigator.geolocation && data) {
            setShowLoading(true);

            /**
             * Este método del sistema obtiene la ubicación actual del dispositivo.  
             */
            navigator.geolocation.getCurrentPosition(
                /**
                 * Maneja la obtención de la posición del usuario mediante el objeto proporcionado por el navegador.
                 * 
                 * @param {Object} pos - Objeto que contiene información sobre la posición del usuario
                 */
                (pos) => {
                    let _current_date = new Date();
                    let _json = {
                        employee_id: info.id,
                        cost_center_uuid: data,
                        cost_center_name: null,
                        cost_center_code: null,
                        type: 1, // reporta ingreso
                        date: _current_date.toISOString().split('T')[0] + " " + _current_date.toTimeString().split(' ')[0],
                        novelty: null,
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                        // lat: "10.425628",
                        // lng: "-75.537717",
                    };

                    if (navigator.onLine) {
                        handleTimeRecordingRemote(_json);
                    } else {
                        handleTimeRecordingLocal(_json);
                    }
                },
                /**
                 * Maneja errores que puedan surgir durante la obtención de la posición del usuario.
                 * 
                 * @param {Error} error - Objeto que representa el error ocurrido durante la obtención de la posición.
                 */
                (error) => {
                    console.error('Error al obtener la ubicación:', error);
                    Alert('Error al obtener la ubicación: ' + error.message, "warning");
                    setShowLoading(false);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 30000,
                    maximumAge: 0,
                }
            );
        } else {
            Alert("Geolocalización no es soportada por este navegador.", "warning");
        }
    }

    /**
     * Esta función maneja los errores que se produzcan a la hora de escanear el código QR. 
     * @param {string} error - Error en formato de texto entregado por la función `QrScanner`. 
     */
    const handleErrorScan = (error) => {
        console.error('Ocurrio un error al escanear el código QR:', error);
        Alert('Ocurrio un error al escanear el código QR: ' + error, "warning");
    }

    /**
     * Esta función pierdes permisos para usar la cámara. 
     * Se llama en el primer renderizado del componente.
     */
    const requestCameraPermission = async () => {

        /**
         * Esta función del sistema pide los permisos de la cámara.
         */
        await navigator.mediaDevices.getUserMedia({ video: true });
        console.log('Permiso de cámara concedido');
    }

    /**
     * Esta función pide permisos de ubicación 
     * Se llama en el primer renderizado del componente.
     */
    const requestPositionPermission = () => {

        /**
         * Verificar si el navegador es compatible con la geolocalización
         */
        if (!navigator.geolocation) {
            Alert('Error: la geolocalización no es compatible con su navegador', "warning");
            return;
        }

        /**
         * Este método del sistema pide los permisos de ubicación. 
         * En este caso no se usa para nada mas. 
         */
        navigator.geolocation.getCurrentPosition(
            () => {
                console.log("Paso datos de ubicación");
            },
            (error) => {
                console.error('Error al obtener la ubicación:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 0,
            });
    }

    React.useEffect(() => {
        document.title = "Escaner QR";

        if (info.auth) {
            if (info.working === 2) {
                requestPositionPermission();
                requestCameraPermission();
            } else {
                navigate("/");
            }
        } else {
            navigate("/login");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container">
            <h1>Leer código QR</h1>
            {(info.auth && info.working === 2) && <Scanner
                onScan={handleScan}
                onError={handleErrorScan}
                showScan={showScan}
            />}
            <button type="button" className="btn btn_primary gray" onClick={() => navigate(-1)}>Volver</button>
            <Loading state={showLoading} />
        </div>
    );
}

export default ScannerQR;