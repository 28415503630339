import * as React from 'react';
import { InputPassword, Input } from 'shared/Components';
import { Alert, alerta, alertRemove } from "shared/Alert";
import { useNavigate } from "react-router";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";


const LogInForm = () => {
    const { setInfo } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);
    const [document, setDocument] = React.useState("");
    const [pass, setPass] = React.useState("");
    const navigate = useNavigate();

    const inputDoc = React.useRef(null);
    const inputPass = React.useRef(null);


    /**
     * Esta función obtiene el estado del trabajador desde el centro de servicios
     * y actualiza los datos del usuario en local storage. 
     * 
     * @param {JSON} info - el `id` del usuario y el nuevo `token` en formato json. 
     */
    const validateStatus = async (info) => {

        let _data = await api.fetchAuth({
            url: "payroll-news/employee-time-recording/employe-current-working-state/" + info.id,
            method: 'GET',
            token: info.token,
            data: {}
        });

        if (_data.success) {
            setInfo({
                working: _data.data.working || 2, // por defecto 2 inactivo
                auth: true,
                id: _data.data.id,
                name: _data.data.name,
                last_name: _data.data.last_name,
                full_name: _data.data.full_name,
                token: info.token,
                cost_center_uuid: _data.data.working_cost_center_uuid
            });

            setTimeout(() => {
                setShowLoading(false);
                navigate("/");
            }, 2000);
        } else {
            setShowLoading(false);
            Alert(_data.message, "warning");
        }
    }

    /**
     * Valida el documento ingresado y almacenado en el hook `document`. 
     * 
     * @returns {boolean} - Devuelve `true` si el documento es válido, de lo contrario, devuelve `false`.
     */
    const validateDocument = () => {
        alertRemove(inputDoc.current);

        if (document == null || document.length < 1) {
            alerta(inputDoc.current, "¡Debes ingresar tu número de documento!");

            return false;
        } else if (document.length < 4) {
            alerta(inputDoc.current, "¡El número de documento es muy corto!");

            return false;
        } else {
            return true;
        }
    }

    /**
     * Valida la contraseña ingresada y almacenada en el hook `pass`. 
     * 
     * @returns {boolean} - Devuelve `true` si la contraseña es válida, de lo contrario, devuelve `false`.
     */
    const validatePass = () => {
        alertRemove(inputPass.current);

        if (pass == null || pass.length < 1) {
            alerta(inputPass.current, "¡Debes ingresar tu código de acceso!");

            return false;
        } else if (pass.length < 4) {
            alerta(inputPass.current, "¡El código de acceso es muy corto!");

            return false;
        } else {
            return true;
        }
    }

    /**
     * Valida los datos del formulario, si son correctos envía la solicitud al centro de servicios. 
     * Si el servicio responde que todo es correcto, redirige al home. 
     * Si el servicio responde que los datos son incorrectos muestra una alerta 
     */
    const validate = async () => {

        if (!navigator.onLine) {
            Alert("Para acceder, necesitas estar conectado a internet.", "warning");
            return;
        }

        if (validateDocument() && validatePass()) {
            setShowLoading(true);
            let _data = await api.fetchUnauth({
                url: "auth/login/employee",
                data: {
                    document_number: document.trim(),
                    password: pass.trim()
                }
            });

            if (_data.success) {
                validateStatus({
                    id: _data.data.user.id,
                    token: _data.data.token
                });
            } else {
                setShowLoading(false);
                Alert(_data.message, "warning");
            }
        }
    }

    return (
        <form className="form">
            <h3>Ingrese la siguiente información</h3>

            <Input
                ref={inputDoc}
                type="text"
                title="Número de documento"
                value={document}
                onChange={v => { alertRemove(inputDoc.current); setDocument(v) }} />

            <InputPassword
                ref={inputPass}
                title="Código de acceso"
                value={pass}
                onChange={v => { alertRemove(inputPass.current); setPass(v) }} />


            <button type="button" className="btn btn_primary" onClick={validate}><Iconos.Login /> Ingresar</button>

            <Loading state={showLoading} />
        </form>
    );
};

export default LogInForm;
