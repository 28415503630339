import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MediaProvider } from './Contexts';
import ScrollToTop from './ScrollToTop';
import Splash from '../view/Splash';
import Router from './Router';
import '../styles/app.scss';

const App = () => {
    const [loading, setLoading] = React.useState(true);

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    React.useEffect(() => {
        document.onselectstart = () => false;

        if (process.env.NODE_ENV === 'production') {
            document.addEventListener("contextmenu", handleContextMenu);

            return () => {
                document.removeEventListener("contextmenu", handleContextMenu);
            }
        }
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop>
                <MediaProvider>
                    {loading ? <Splash onLoad={v => setLoading(v)} /> : <Router />}
                </MediaProvider>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
