import * as React from "react";
import { useNavigate } from 'react-router-dom';
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import api from "./api";

const ContextMedia = React.createContext();

export function MediaProvider({ children }) {
    const [info, configInfo] = React.useState(JSON.parse(localStorage.getItem("user")) ?? { auth: false, token: "not apply" });
    const [taskCounter, setTaskCounter] = React.useState((JSON.parse(localStorage.getItem("pending_tasks")) ?? []).length);
    const [modalView, setModalView] = React.useState(null);
    const navigate = useNavigate();


    const setInfo = (_new) => {
        configInfo(_old => {
            let _json;
            if (_new.clear) {
                localStorage.removeItem('user');
                _json = { auth: false, token: "not apply" };
                console.log("esta limpiando", _json);
            } else if (_new.check) {
                if (_old.working === 0) {
                    _old.working = _old.prework;
                }
                console.log("esta checando", _old);
                localStorage.setItem('user', JSON.stringify(_old));
            } else {
                if (_new.working === 0) {
                    _old.prework = _old.working;
                }
                _json = { ..._old, ..._new }
                if (taskCounter > 0) {
                    _json.working = 2;
                }
                console.log("esta actualizando", _json);
                localStorage.setItem('user', JSON.stringify(_json));
            }
            return _json;
        });
    }

    const setTask = (_info) => {
        let _tasks = JSON.parse(localStorage.getItem("pending_tasks")) ?? [];
        _tasks.push(_info);
        localStorage.setItem('pending_tasks', JSON.stringify(_tasks));
        setTaskCounter(_tasks.length);
        return _tasks;
    }

    const synchronizeTasks = async (_token = info.token) => {
        let _tasks = JSON.parse(localStorage.getItem("pending_tasks")) ?? [];

        if (!info.auth || _tasks.length < 1) {
            return;
        }

        if (!navigator.onLine) {
            Alert("Para actualizar y sincronizar novedades debes tener conexión a internet.", "wifi_off");
            return;
        }

        setModalView(<Loading state={true} />);
        console.log("sincronizando tareas pendientes", _tasks.length);

        let _data = await api.fetchAuth({
            url: "payroll-news/employee-time-recording/multiple-save",
            method: 'POST',
            token: _token,
            data: {
                employee_id: info.id,
                time_recording: _tasks
            }
        });

        if (_data.success && _data.type_message === "success") {
            Alert(`${_tasks.length} novedad${_tasks.length > 1 ? "es" : ""} sincronizada${_tasks.length > 1 ? "s" : ""} correctamente`, "ok", () => {
                localStorage.removeItem('pending_tasks');
                setTaskCounter(0);
            });
        } else if (_data.type_message === "unauthenticated_error") {
            Alert("Tu sesión ha vencido. Por favor inicia sesión nuevamente", "warning", () => {
                navigate("/login");
            });
        } else {
            console.error("No se pudieron sinconizar las tareas" + _data.message);
            Alert('Ocurrio un error: ' + _data.message, "warning");
        }

        setModalView(null);
    }

    return <ContextMedia.Provider value={{ info, setInfo, setTask, synchronizeTasks, taskCounter, setTaskCounter }}>
        {children}
        {modalView}
    </ContextMedia.Provider>
}

export { ContextMedia };