import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'shared/Alert';
import Loading from 'shared/Loading';
import { useMedia } from 'hooks';
import api from 'services/api';

const ConfirmLogOut = () => {
    const navigate = useNavigate();
    const { info, setInfo } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);


    /**
     * Maneja la acción del botón cancelar 
     */
    const handleCancel = () => {
        setInfo({
            working: info.old_working
        });
    }

    /**
     * Maneja la acción del botón aceptar. 
     */
    const handleAcept = async () => {
        if (navigator.onLine) {
            setShowLoading(true);
            let _data = await api.fetchAuth({
                url: "auth/logout",
                method: 'GET',
                token: info.token,
                data: {}
            });

            if (_data.success || _data.type_message === "unauthenticated_error") {
                navigate("/login");
            } else {
                Alert('Ocurrio un error: ' + _data.message, "warning");
            }

            setShowLoading(false);
        } else {
            Alert("Necesitas de conexión a internet para cerrar sesión", "wifi_off");
        }
    }

    return (
        <div className="card_state gray">
            <h1>
                ¿Estás seguro que quieres cerrar sesión? <br /><br />
                Si lo haces, tendrás que volver a iniciar sesión la próxima vez que quieras acceder. <br /><br />
                <strong>Cada vez que inicies sesión, asegúrate de tener conexión a internet.</strong>
            </h1>
            <button type="button" className="btn btn_secondary" onClick={handleCancel}> Cancelar</button>
            <button type="button" className="btn btn_primary" onClick={handleAcept}> Aceptar</button>
            <Loading state={showLoading} />
        </div>
    );
}

export default ConfirmLogOut;