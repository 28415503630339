const WEB_API = process.env.REACT_APP_WEB_API;

/* eslint import/no-anonymous-default-export: 0 */

class API {

    /**
     * Verifica el estado de la respuesta en la consulta 
     * 
     * @param {Object} response - La respuesta generada en la consulta 
     */
    checkStates(response) {
        switch (response.status) {
            case 200:
            case 210:
                //console.log('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                console.log('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
            case 501:
            case 502:
                console.log('Error', response.status);
                // throw new Error('500 - Ocurrio un error en la sintaxis!');
                break;
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo mas a fondo`);
        }
    }

    /**
     * Hace una consulta al centro de servicios con autenticación
     * 
     * @param {JSON} params - Los parámetros de la consulta en formato JSON. 
     * @param {String} params.url - URL de la consulta.
     * @param {String} params.method - Método HTTP de la consulta.
     * @param {Object} params.data - Datos de la consulta en formato JSON.
     * @param {String} params.token - Token de autenticación para acceder al servicio.
     * @return {Promise<JSON>} Una promesa que se resuelve con la respuesta del servicio en formato JSON.
     */
    async fetchAuth(params) {
        let data;
        let options = params.method === 'GET' ?
            {
                method: params.method,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + params.token
                }
            }
            :
            {
                method: params.method,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + params.token
                },
                body: JSON.stringify(params.data)
            };

        await fetch(WEB_API + params.url, options)
            .then(response => {
                this.checkStates(response);
                return response.json();
            })
            .then(response => {
                data = response;
            })
            .catch((error) => {
                data = { "success": false, "message": error.toString() };
            });

        return data;
    }

    /**
     * Hace una consulta al centro de servicios sin autenticación
     * Sólo aplica para método post.
     * 
     * @param {JSON} params - Los parámetros de la consulta en formato JSON.
     * @param {String} params.url - URL de la consulta.
     * @param {Object} params.data - Datos de la consulta en formato JSON.
     * @return {Promise<JSON>} Una promesa que se resuelve con la respuesta del servicio en formato JSON.
     */
    async fetchUnauth(params) {
        let data;

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.checkStates(response);
                return response.json();
            })
            .then(response => {
                data = response;
            })
            .catch((error) => {
                data = { "success": false, "message": error.toString() };
            });

        return data;
    }
}

export default new API();